<template>
  <div>
    <v-container>
      <v-row>   

        <v-col cols="12" md="6">
          <div id="chart">
            <v-card id="chart">
              <apexchart
                type="pie"
                height="400"
                :options="chartOptionsGov"
                :series="seriesGov"
              ></apexchart>
            </v-card>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <div id="chart">
            <v-card id="chart">
              <apexchart
                type="pie"
                height="400"
                :options="chartOptionsTem"
                :series="seriesTem"
              ></apexchart>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import VueMoment from "vue-moment";
import moment from "moment-timezone";
export default {
  name: "personnel_etc",
  data() {
    return {
      VueMoment,
      moment,
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      personnel_etcs: [],


      seriesGov: [],
      chartOptionsGov: {
        chart: {
          width: "30%",
          type: "pie"
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          }
        },
        labels: [],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        title: {
          text: "จำนวนพนักงานราชการ สายการสอน"
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          }
        },
        legend: {
          position: "left"
        },
        responsive: [
          {
            options: {
              chart: {},
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },

      numY: [],

      seriesTem: [],
      chartOptionsTem: {
        chart: {
          width: "30%",
          type: "pie"
        },    
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        title: {
          text: "จำนวนครูอัตราจ้าง"
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          }
        },
        legend: {
          position: "left"
        },
        responsive: [
          {
            options: {
              chart: {},
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      numZ: []
    };
  },
  created() {
    this.personnel_etcQueryAll();
  },

  methods: {
    async personnel_etcQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_etc.php", {
          ApiKey: this.ApiKey,
          subjectCount: "Ok"
        })
        .then(result => {
          result.data.forEach(element => {          
            this.numY.push(element.subject_typeName + ' : ' + parseInt(element.countPersonSubjectTeachGov));
            this.seriesGov.push(parseInt(element.countPersonSubjectTeachGov));

            this.numZ.push(element.subject_typeName + ' : ' + parseInt(element.countPersonSubjectTeachTem));
            this.seriesTem.push(parseInt(element.countPersonSubjectTeachTem));
          });
        
          this.chartOptionsGov = { labels: this.numY };
          this.chartOptionsTem = { labels: this.numZ };
        })
        .finally(() => (this.loading = false));       
    }
  }
};
</script>

<style scoped>
#chart {
  margin: 5px auto;
}
</style>
