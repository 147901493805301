<template>
  <v-container id="dashboard" fluid tag="section">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="green"
      class="mb-0"
    ></v-progress-linear>

    <v-row no-gutters>
      <v-col cols="12" sm="12">
        <base-material-card
          color="primary"
          icon="mdi-account-group"
          title="รายงานผล"
        >
            
          <v-form
            class="mb-4 pa-2"
            v-if="period_teach.period_enable_process === '1'"
          >
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-alert
                  border="top"
                  colored-border
                  type="info"
                  elevation="2"
                  class="text-center"
                >
                การย้ายข้าราชการครูและบุคลากรทางการศึกษา ตำแหน่งครู
                  <h4>
                    จำนวนวิทยาลัยที่ดำเนินการ :
                    {{ conditions_transfers.count_condition }} แห่ง
                    คิดเป็นร้อยละ {{ conditions_transfers.percent_t }}
                  </h4>
                  <h4>
                    ผู้ยื่นย้าย <span style="color: blue;">กรณีปกติ</span>  จำนวน :
                    {{ tpsnormal.count_personnel }} คน :
                    คิดเป็นร้อยละ {{ tpsnormal.percent_p }}
                  </h4>
                  <h4>
                    ผู้ยื่นย้าย <span style="color: red;">กรณีพิเศษ</span> จำนวน :
                    {{ tpsspecial.count_personnel }} คน :
                    คิดเป็นร้อยละ {{ tpsspecial.percent_p }}
                  </h4>

                </v-alert></v-col
              >
            </v-row>
          </v-form>

          <v-form
            class="mb-4 pa-2"
            v-if="
              period_manage.period_enable_process === '1' ||
                period_manage.period_enable === '1'
            "
          >
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-alert
                  border="top"
                  colored-border
                  type="info"
                  elevation="2"
                  class="text-left"
                >
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account-multiple
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h2>ผู้ยื่นย้าย ตำแหน่งผู้อำนวยการ</h2>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action></v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> <h4>จำนวน</h4> </v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>{{ transference_manages.count_dr }} คน</h3>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title> <h4>ร้อยละ</h4> </v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>
                            {{ transference_manages.percent_dr }}
                          </h3></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          <h4>กรณีพิเศษ</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>
                            {{ transference_manages.count_dr_special }} รายการ
                          </h3>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title> <h4>ปกติ</h4> </v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>
                            {{ transference_manages.count_dr_normal }} รายการ
                          </h3></v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h4>พัฒนาคุณภาพ</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>
                            {{ transference_manages.count_dr_quality }} รายการ
                          </h3></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-alert></v-col
              >
              <v-col cols="12" md="6">
                <v-alert
                  border="top"
                  colored-border
                  type="info"
                  elevation="2"
                  class="text-left"
                >
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account-multiple
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <h2>ผู้ยื่นย้าย ตำแหน่งรองผู้อำนวยการ</h2>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action></v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> <h4>จำนวน</h4> </v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>{{ transference_manages.count_sedr }} คน</h3>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title> <h4>ร้อยละ</h4> </v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>
                            {{ transference_manages.percent_sedr }}
                          </h3></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          <h4>กรณีพิเศษ</h4>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>
                            {{ transference_manages.count_sedr_special }} รายการ
                          </h3>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title> <h4>ปกติ</h4> </v-list-item-title>
                        <v-list-item-subtitle>
                          <h3>
                            {{ transference_manages.count_sedr_normal }} รายการ
                          </h3></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-alert></v-col
              >
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
<v-col cols="12">
        <personnel_temporary_notifyVue/>
      </v-col>
      <v-col cols="12">


        <Personnel_etcSummary_years/>
        <personnel_etcSummarySubject/>
        <personnel_etcSummary/>
      </v-col>
      <v-col cols="12">
        <personnel_etcSummaryBranch/>
      </v-col>
      <v-col cols="12">
        <temporarycollegeemreg/>
      </v-col>
      <v-col cols="12">
        <temporaryemployees/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import personnel_temporary_notifyVue from '../../component/personnel_temporary_notify.vue';
import personnel_etcSummary from '../../component/personnel_etcSummary.vue';
import personnel_etcSummarySubject from '../../component/personnel_etcSummarySubject.vue';
import Personnel_etcSummary_years from '../../component/personnel_etcSummary_years.vue';


import personnel_etcSummaryBranch from '../../component/personnel_etcSummaryBranch.vue';

import temporarycollegeemreg from '../../component/temporarycollegeemreg.vue';
import temporaryemployees from '../../component/temporaryemployees';

export default {
  name: "DashboardDashboard",
  components: { personnel_temporary_notifyVue ,temporarycollegeemreg,temporaryemployees,personnel_etcSummary,personnel_etcSummaryBranch,personnel_etcSummarySubject,Personnel_etcSummary_years},
  data() {
    return {
      headers: [
        { text: "รหัสสถานศึกษา", align: "left", value: "college_code" },
        { text: "ชื่อสถานศึกษา", align: "left", value: "college_name" },
        { text: "อำเภอ", align: "left", value: "prefecture_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ประเภทสถานศึกษา", align: "left", value: "collegetype_name" },
        { text: "เบอร์โทร", align: "left", value: "collegeinfo_phone" }
      ],
      header_branchs: [
        { text: "รหัสสาขา", align: "center", value: "id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "รายละเอียด", align: "left", value: "detail_branch" }
      ],
      time_ss: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      search: "",
      search_branch: "",
      pagination: {},
      ApiKey: "HRvec2021",
      interval: {},
      value: 0,
      loading: true,
      dialogLoading: true,
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      personnel_rangChart: {
        data: {
          labels: ["ครูผู้ช่วย", "คศ.1", "คศ.2", "คศ.3", "คศ.4", "คศ.5"],
          series: [[0, 0, 0, 0, 0, 0]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 5000,
          chartPadding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 0
          }
        }
      },

      Personnel_chart: {
        data: {
          labels: [
            "ผู้อำนวยการ",
            "รองผู้อำนวยการ",
            "ครู",
            "ครูผู้ช่วย",
            "ศึกษานิเทศก์"
          ],
          series: [[0, 0, 0, 0, 0]]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 14000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 10
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 100%)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },

      user: [],
      showAlldata_rang: [],
      colleges: [],
      provinces: [],
      prefectures: [],
      regions: [],
      region_ena: true,
      showAlldata: {},
      showcollegedata: {},
      showbranchdata: [],
      tpsnormal: [],
      tpsspecial: [],
      conditions_transfers: [],
      times_select: "1",
      years_select: "2565",
      period_teach: [],
      period_manage: [],
      transference_manages: []
    };
  },

  async mounted() {
    let result;
    result = await this.$http.post("collegetype.php", {
      ApiKey: this.ApiKey
    });
    this.collegetypes = result.data;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;
    result = await this.$http.post("province.php", {
      ApiKey: this.ApiKey
    });
    this.provinces = result.data;

    result = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result.data;

    await this.period_teachQuery();
    await this.period_manageQuery();
    await this.getuser();
    await this.getAlldata();
    await this.getAlldata_rang();
    await this.getPersonnelChart();
    await this.getPersonnel_rangChart();
    await this.getAllbranchdata();
    await this.searchTimeYear();
    this.loading = false;  
 
  },


  methods: {
    async period_teachQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_type: "teacher"
      });
      this.period_teach = result_period.data;
    },

    async period_manageQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_enable: "1",
        period_type: "manage",
        periord_admin: "check"
      });
      this.period_manage = result_period.data;  
    },

    async searchTimeYear() {
      if (this.period_teach.period_enable === "1" || this.period_teach.period_enable_process ==='1') {
        let result = await this.$http.post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.period_teach.period_times,
          year_s: this.period_teach.period_year,
          type_move:'nm',
          count_s: "ok"
        });
        this.tpsnormal = result.data;

        let resultsp = await this.$http.post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.period_teach.period_times,
          year_s: this.period_teach.period_year,
          type_move:'sp',
          count_s: "ok"
        });
        this.tpsspecial = resultsp.data;



        

        let result_con = await this.$http
          .post("conditions_transfer_admin.php", {
            ApiKey: this.ApiKey,
            time_s: this.period_teach.period_times,
            year_s: this.period_teach.period_year,
            count_s: "ok"
          })      
        this.conditions_transfers = result_con.data;
        
        
        
      }

      if (
        this.period_manage.period_enable_process === "1" ||
        this.period_manage.period_enable === "1"
      ) {
        let result = await this.$http.post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.period_manage.period_times,
          manage_year_s: this.period_manage.period_year,
          count_s: "ok"
        });
        this.transference_manages = result.data;
      }
    },

    async getuser() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        ApiKey: "HRvec2021"
      });
      this.user = result.data;
    },

    async getAlldata() {
      let result = await this.$http.post("show_dashboard_all.php");
      this.showAlldata = result.data;
    },

    async getAlldata_rang() {
      let result = await this.$http.post("show_dachboard_rang.php");
      this.showAlldata_rang = result.data;
    },

    async getPersonnelChart() {
      this.Personnel_chart.data.series = [
        [
          this.num_dr,
          this.num_se_dr,
          this.num_se_tech,
          this.num_se_techprepare,
          this.num_se_supervision
        ]
      ];
    },

    async getPersonnel_rangChart() {
      this.personnel_rangChart.data.series = [
        [
          this.num_rang_0,
          this.num_rang_1,
          this.num_rang_2,
          this.num_rang_3,
          this.num_rang_4,
          this.num_rang_5
        ]
      ];
    },

    async getAllbranchdata() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.showbranchdata = result.data;
    }
  },
  computed: {
    num_dr() {
      let result = parseInt(this.showAlldata.count_dr);
      return result;
    },
    num_se_dr() {
      let result = parseInt(this.showAlldata.count_se_dr);
      return result;
    },
    num_se_tech() {
      let result = parseInt(this.showAlldata.count_tech);
      return result;
    },
    num_se_techprepare() {
      let result = parseInt(this.showAlldata.count_perpare);
      return result;
    },
    num_se_supervision() {
      let result = parseInt(this.showAlldata.count_supervision);
      return result;
    },

    num_rang_0() {
      let result = parseInt(this.showAlldata_rang.count_rang0);
      return result;
    },
    num_rang_1() {
      let result = parseInt(this.showAlldata_rang.count_rang1);
      return result;
    },
    num_rang_2() {
      let result = parseInt(this.showAlldata_rang.count_rang2);
      return result;
    },
    num_rang_3() {
      let result = parseInt(this.showAlldata_rang.count_rang3);
      return result;
    },
    num_rang_4() {
      let result = parseInt(this.showAlldata_rang.count_rang4);
      return result;
    },
    num_rang_5() {
      let result = parseInt(this.showAlldata_rang.count_rang5);
      return result;
    }
  }
};
</script>
