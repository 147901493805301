<template>
    <div>    
      <v-container>
        <base-material-card
          icon="mdi-clipboard-text"
          title="ผู้สมัครตามประกาศ คัดเลือก/แข่งขัน บุคลากร"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row>
              <v-col cols="12" md="6" class="text-right">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="temEmIDcardIDMainSearch"
                  :items="temporarycollegeemregSelect"
                  item-text="temCollegeEmRegPositionDetail"
                  item-value="temCollegeEmRegID"
                  outlined
                  label="ค้นหา : ประกาศ"
                  @change="temporarycollegeemregSearch()"
                >
                  <template v-slot:item="{ item }">
                    <div class="pa-2">
                      <div class="font-weight-black">
                        {{ item.temCollegeEmRegID }} :
                        <span
                          v-if="
                            item.temCollegeEmRegPosition === 'governmentteach'
                          "
                          >พนักงานราชการ(สายการสอน)</span
                        >
                        <span
                          v-else-if="
                            item.temCollegeEmRegPosition === 'governmentsp'
                          "
                          >พนักงานราชการ(สายสนับสนุน)</span
                        >
                        <span
                          v-else-if="item.temCollegeEmRegPosition === 'temporary'"
                          >ครูอัตราจ้าง</span
                        >
                        <span
                          v-else-if="
                            item.temCollegeEmRegPosition === 'temporary_job'
                          "
                          >ลูกจ้างชั่วคราว</span
                        >
                      </div>
                      <div>
                        {{ item.temCollegeEmRegPositionDetail }} :
                        <span v-if="item.temCollegeEmRegHiring === 'month'"
                          >รายเดือน</span
                        >
                        <span v-else-if="item.temCollegeEmRegHiring === 'day'"
                          >รายวัน</span
                        >
                        <span v-else-if="item.temCollegeEmRegHiring === 'hour'"
                          >รายชั่วโมง</span
                        >
                        : {{ item.temCollegeEmRegSalary }} บาท
                      </div>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card>
          <v-data-table
            color="success"
            :loading="loading"
            :headers="headers"
            :items="temporaryemployeess"
            :search="search"
          >
            <template v-slot:item.temCollegeEmRegPosition="{ item }">
              <span v-if="item.temCollegeEmRegPosition === 'governmentteach'"
                >พนักงานราชการ(สายการสอน)</span
              >
              <span v-else-if="item.temCollegeEmRegPosition === 'governmentsp'"
                >พนักงานราชการ(สายสนับสนุน)</span
              >
              <span v-else-if="item.temCollegeEmRegPosition === 'temporary'"
                >ครูอัตราจ้าง</span
              >
              <span v-else-if="item.temCollegeEmRegPosition === 'temporary_job'"
                >ลูกจ้างชั่วคราว</span
              >
            </template>
  
            <template v-slot:item.temCollegeEmRegHiring="{ item }">
              <span v-if="item.temCollegeEmRegHiring === 'month'">รายเดือน</span>
              <span v-else-if="item.temCollegeEmRegHiring === 'day'">รายวัน</span>
              <span v-else-if="item.temCollegeEmRegHiring === 'hour'"
                >รายชั่วโมง</span
              >
            </template>
  
            <template v-slot:item.temEmDateTime="{ item }">
              {{
                item.temEmDateTime
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
  
            <template v-slot:item.actions="{ item }">
              <v-icon
                color="yellow"
                @click.stop="temporaryemployeesEdit(item.temEmIDReGis)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.action_s="{ item }">
              <v-icon
                color="red"
                @click.stop="temporaryemployeesDelete(item.temEmIDReGis)"
              >
                mdi-delete
              </v-icon>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
              >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
            >
          </v-data-table>
        </base-material-card>
  
           <!-- V-model deletetemporaryemployeesdialog -->
        <v-layout>
          <v-dialog
            v-model="deletetemporaryemployeesdialog"
            persistent
            max-width="60%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบข้อมูลประกาศ คัดเลือก/แข่งขัน บุคลากร"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form ref="deletetemporaryemployeesform" lazy-validation>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            ยืนยันการลบข้อมูลประกาศ คัดเลือก/แข่งขัน บุคลากร
                            <v-chip color="info" class="font-weight-bold">
                              <span
                                v-if="
                                  edittemporaryemployees.temCollegeEmRegPosition ===
                                    'governmentteach'
                                "
                                >พนักงานราชการ(สายการสอน)</span
                              >
                              <span
                                v-else-if="
                                  edittemporaryemployees.temCollegeEmRegPosition ===
                                    'governmentsp'
                                "
                                >พนักงานราชการ(สายสนับสนุน)</span
                              >
                              <span
                                v-else-if="
                                  edittemporaryemployees.temCollegeEmRegPosition ===
                                    'temporary'
                                "
                                >ครูอัตราจ้าง</span
                              >
                              <span
                                v-else-if="
                                  edittemporaryemployees.temCollegeEmRegPosition ===
                                    'temporary_job'
                                "
                                >ลูกจ้างชั่วคราว</span
                              >
                              {{
                                edittemporaryemployees.temCollegeEmRegPositionDetail
                              }}
                            </v-chip>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large @click.stop="deletetemporaryemployeesdialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deletetemporaryemployeesSubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model edittemporaryemployeesdialog -->
        <v-layout row justify-center>
          <v-dialog
            v-model="edittemporaryemployeesdialog"
            persistent
            max-width="80%"
          >
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขประกาศ คัดเลือก/แข่งขัน บุคลากร"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-row align="center">
                  <v-form ref="edittemporaryemployeesform" lazy-validation>
                    <v-container no-gutters>
                      <v-layout wrap>
                        <v-flex md12>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            @input="validateInput()"
                            outlined
                            v-model="edittemporaryemployees.temEmIDcard"
                            label="เลขที่บัตรประจำตัวประชาชน"
                            :rules="[
                              v => !!v || 'This field is required',
                              v => (!!v && v.length == 13) || '13  ID Card'
                            ]"
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md4>
                          <v-autocomplete
                            :items="prefixs"
                            item-text="prefix_name"
                            item-value="prefix_name"
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="คำนำหน้าชื่อ"
                            v-model="edittemporaryemployees.temEmTitles"
                            required
                            :rules="[v => !!v || '']"
                          ></v-autocomplete>
                        </v-flex>
  
                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="ชื่อ"
                            v-model="edittemporaryemployees.temEmFname"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="นามสกุล"
                            v-model="edittemporaryemployees.temEmLname"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="สัญชาติ"
                            v-model="edittemporaryemployees.temEmNationality"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="เชื้อชาติ"
                            v-model="edittemporaryemployees.temEmEthnicity"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md4>
                          <v-text-field
                            prepend-icon="mdi-account-settings"
                            outlined
                            label="ศาสนา"
                            v-model="edittemporaryemployees.temEmReligion"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md12>
                          <hr class="mb-2 hrStyle" />
                          <div class="ma-2">ภูมิลำเนาบ้านเกิด</div>
                        </v-flex>
  
                        <v-flex md6>
                          <v-dialog
                            ref="dialog2"
                            v-model="modal2"
                            :return-value.sync="
                              edittemporaryemployees.temEmBrithDay
                            "
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="edittemporaryemployees.temEmBrithDay"
                                label="วันเดือนปีเกิด"
                                outlined
                                :rules="[v => !!v || '']"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="edittemporaryemployees.temEmBrithDay"
                              scrollable
                              locale="th"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="modal2 = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.dialog2.save(
                                    edittemporaryemployees.temEmBrithDay
                                  )
                                "
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-home"
                            outlined
                            label="ภูมิลำเนาบ้านเกิด"
                            v-model="edittemporaryemployees.temEmAddBorn"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home"
                            outlined
                            label="จังหวัด"
                            v-model="edittemporaryemployees.temEmProvince"
                            required
                            :items="provinces"
                            item-text="province_name"
                            item-value="province_ID"
                            :rules="[v => !!v || '']"
                            @change="prefectureQuery()"
                          ></v-autocomplete>
                        </v-flex>
  
                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home"
                            outlined
                            label="อำเภอ"
                            :items="prefectures"
                            item-text="prefecture_name"
                            item-value="prefecture_ID"
                            v-model="edittemporaryemployees.temEmDistrictAm"
                            required
                            :rules="[v => !!v || '']"
                            @change="districtQuery()"
                          ></v-autocomplete>
                        </v-flex>
  
                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home"
                            outlined
                            label="ตำบล"
                            :items="districts"
                            item-text="district_name"
                            item-value="district_ID"
                            v-model="edittemporaryemployees.temEmDistrict"
                            required
                            :rules="[v => !!v || '']"
                          ></v-autocomplete>
                        </v-flex>
  
                        <v-flex md12>
                          <hr class="mb-2 hrStyle" />
                          <div class="ma-2">ที่อยู่ปัจจุบัน</div>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="ที่อยู่ปัจจุบัน"
                            v-model="edittemporaryemployees.temEmHomeIdNow"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="ถนน"
                            v-model="edittemporaryemployees.temEmRordNow"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="จังหวัด"
                            :items="provinces"
                            item-text="province_name"
                            item-value="province_ID"
                            v-model="edittemporaryemployees.temEmProvinceNow"
                            required
                            :rules="[v => !!v || '']"
                            @change="prefectureSearch()"
                          ></v-autocomplete>
                        </v-flex>
  
                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="อำเภอ"
                            :items="prefectures2"
                            item-text="prefecture_name"
                            item-value="prefecture_ID"
                            v-model="edittemporaryemployees.temEmDistrictAmNow"
                            required
                            :rules="[v => !!v || '']"
                            @change="districtSearch()"
                          ></v-autocomplete>
                        </v-flex>
  
                        <v-flex md6>
                          <v-autocomplete
                            prepend-icon="mdi-home-map-marker"
                            outlined
                            label="ตำบล"
                            :items="districts2"
                            item-text="district_name"
                            item-value="district_ID"
                            v-model="edittemporaryemployees.temEmDistrictNow"
                            required
                            :rules="[v => !!v || '']"
                          ></v-autocomplete>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-phone-classic"
                            outlined
                            label="เบอร์โทรติดต่อ"
                            v-model="edittemporaryemployees.temEmTel"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md12>
                          <hr class="mb-2 hrStyle" />
                          <div class="ma-2">การศึกษา</div>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-school"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปวช. สาขา"
                            v-model="edittemporaryemployees.temEmEduCh"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-domain"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปวช. จาก"
                            v-model="edittemporaryemployees.temEmEduChFrom"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-school"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปวส. สาขา"
                            v-model="edittemporaryemployees.temEmEduSh"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-domain"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปวส. จาก"
                            v-model="edittemporaryemployees.temEmEduShFrom"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-school"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปริญญาตรี. สาขา"
                            v-model="edittemporaryemployees.temEmEduBache"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-domain"
                            outlined
                            label="สำเร็จการศึกษา ระดับ ปริญญาตรี. จาก"
                            v-model="edittemporaryemployees.temEmEduBacheFrom"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-school"
                            outlined
                            label="สำเร็จการศึกษา ระดับ อื่นๆ สาขา"
                            v-model="edittemporaryemployees.temEmEduEtc"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md6>
                          <v-text-field
                            prepend-icon="mdi-domain"
                            outlined
                            label="สำเร็จการศึกษา ระดับ อื่นๆ จาก"
                            v-model="edittemporaryemployees.temEmEduEtcFrom"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md12>
                          <hr class="mb-2 hrStyle" />
                        </v-flex>
  
                        <v-flex md12>
                          <v-text-field
                            prepend-icon="mdi-account-network"
                            outlined
                            label="มีประสบการณ์ในการทำงานด้าน"
                            v-model="edittemporaryemployees.temEmExperWork"
                            required
                          ></v-text-field>
                        </v-flex>
  
                        <v-flex md12>
                          <v-text-field
                            prepend-icon="mdi-transit-transfer"
                            outlined
                            label="มีความรู้ความสามารถพิเศษด้าน"
                            v-model="edittemporaryemployees.temEmExperSp"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex md12>
                          <v-text-field
                            prepend-icon="mdi-email"
                            outlined
                            label="Email สำหรับการติดตามสถานะ/การลงทะเบียน Gmail เท่านั้น"
                            v-model="edittemporaryemployees.temEmEmail"
                            required
                            :rules="emailRules"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="edittemporaryemployeesdialog = false"
                  rounded
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="edittemporaryemployeesSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          top
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <v-card-text>
            {{ snackbar.text }}
          </v-card-text>
  
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.show = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        loading: true,
        ApiKey: "HRvec2021",
        valid: true,
        addtemporaryemployeesdialog: false,
        edittemporaryemployeesdialog: false,
        deletetemporaryemployeesdialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
        temporaryemployeess: [],
        addtemporaryemployees: {},
        edittemporaryemployees: {},
        search: "",
        pagination: {},
        headers: [
          { text: "รหัสใบสมัคร", align: "left", value: "temEmIDReGis" },
          { text: "ID Card", align: "left", value: "temEmIDcard" },
          { text: "ชื่อ-นามสกุล", align: "left", value: "temEmName" },
          {
            text: "เบอร์โทร",
            align: "left",
            value: "temEmTel"
          },
          {
            text: "ตำแหน่ง",
            align: "left",
            value: "temCollegeEmRegPosition"
          },
          {
            text: "รายละเอียดตำแหน่ง",
            align: "left",
            value: "temCollegeEmRegPositionDetail"
          },
  
          { text: "วันที่ทำรายการ", align: "center", value: "temEmDateTime" },
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "action_s",
            icon: "mdi-delete-forever"
          }
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
  
        college: {},
        provinces: [],
        prefectures: [],
  
        collgegs: [],
        temporaryemployeesstatus: [],
        regions: [],
        region_ena: true,
        documentsLinkfiles: null,
        documentsLinkfilesUpdate: null,
        rules: [
          value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
          value => !!value || ""
        ],
        position_select: [
          { text: "พนักงานราชการ(สายการสอน)", value: "governmentteach" },
          { text: "พนักงานราชการ(สายสนับสนุน)", value: "governmentsp" },
          { text: "ครูอัตราจ้าง", value: "temporary" },
          { text: "ลูกจ้างชั่วคราว", value: "temporary_job" }
        ],
        hiring: [
          { text: "รายเดือน", value: "month" },
          { text: "รายวัน", value: "day" },
          { text: "รายชั่วโมง", value: "hour" }
        ],
        user: [],
        temporarycollegeemregSelect: [],
        temEmIDcardIDMainSearch: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        menu: false,
        modal: false,
        modal2: false,
        districts: [],
        districts2: [],
        prefectures: [],
        prefectures2: [],
        provinces: [],
        emailRules: [
          v => !!v || "E-mail is required",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        prefixs:[]
      };
    },
    async mounted() {
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http.post("user.php", {
        ApiKey: this.ApiKey,
        user_ID: userSession.user_ID
      });
      this.user = result.data;
      this.temporaryemployeesQueryAll();
      this.temporarycollegeemregQuery();
      await this.provinceQuery();
      await this.prefixQuery();
    },
    methods: {
        async prefixQuery() {
      let result = await this.$http.post("prefix.php", {
        ApiKey: this.ApiKey
      });
      this.prefixs = result.data;
    },
      async districtQuery() {
        let result = await this.$http.post("districts.php", {
          ApiKey: this.ApiKey,
          prefecture_ID: this.addtemporarycollegeemreg.temEmDistrictAm
        });
        this.districts = result.data;
      },
  
      async districtSearch() {
        let result = await this.$http.post("districts.php", {
          ApiKey: this.ApiKey,
          prefecture_ID: this.addtemporarycollegeemreg.temEmDistrictAmNow
        });
        this.districts2 = result.data;
      },
  
      async prefectureQuery() {
        let result = await this.$http.post("prefecture.php", {
          ApiKey: this.ApiKey,
          province_ID: this.addtemporarycollegeemreg.temEmProvince
        });
        this.prefectures = result.data;
      },
  
      async prefectureSearch() {
        let result = await this.$http.post("prefecture.php", {
          ApiKey: this.ApiKey,
          province_ID: this.addtemporarycollegeemreg.temEmProvinceNow
        });
        this.prefectures2 = result.data;
      },
  
      async provinceQuery() {
        let result = await this.$http.post("province.php", {
          ApiKey: this.ApiKey
        });
        this.provinces = result.data;
      },
  
      async temporarycollegeemregSearch() {
        this.loading = true;
        let result = await this.$http
          .post("temporaryemployees.php", {
            ApiKey: this.ApiKey,
            temEmIDcardIDMain: this.temEmIDcardIDMainSearch
          })
          .finally(() => (this.loading = false));
        this.temporaryemployeess = result.data;
      },
      async temporarycollegeemregQuery() {
        let result = await this.$http.post("temporarycollegeemreg.php", {
          ApiKey: this.ApiKey,
          temCollegeEmRegCode: this.user.college_code
        });
        this.temporarycollegeemregSelect = result.data;
      },
      async temporaryemployeesQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("temporaryemployees.php", {
            ApiKey: this.ApiKey,
            temEmCollegeCode: this.user.college_code
          })
          .finally(() => (this.loading = false));
        this.temporaryemployeess = result.data;
      },
  
      async temporaryemployeesAdd() {
        this.addtemporaryemployees = {};
        this.addtemporaryemployeesdialog = true;
      },
  
      async addtemporaryemployeesSubmit() {
        let result = "";
        let uploaded = null;
  
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.user.college_code + "" + this.time_stamp + "" + "notify.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);
  
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
  
          if (result.data.status == true) {
            this.addtemporaryemployees.temCollegeEmRegDetail = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
  
        this.addtemporaryemployees.ApiKey = this.ApiKey;
        this.addtemporaryemployees.temCollegeEmRegCode = this.user.college_code;
        if (this.addtemporaryemployees.temCollegeEmStatus_sw == true)
          this.addtemporaryemployees.temCollegeEmStatus = "1";
        else this.addtemporaryemployees.temCollegeEmStatus = "0";
  
        result = await this.$http.post(
          "temporaryemployees.insert.php",
          this.addtemporaryemployees
        );
        ;
        if (result.data.status && uploaded) {
          await this.temporaryemployeesQueryAll();
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          if (!uploaded) {
            Swal.fire({
              icon: "warning",
              title: "แนบไฟล์ไม่ถูกต้อง",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "บันทึกข้อมูลผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        this.addtemporaryemployeesdialog = false;
      },
      async temporaryemployeesEdit(temEmIDReGis) {
        let result = await this.$http.post("temporaryemployees.php", {
          ApiKey: this.ApiKey,
          temEmIDReGis: temEmIDReGis
        });
        this.edittemporaryemployees = result.data;
        this.edittemporaryemployeesdialog = true;
      },
      async edittemporaryemployeesSubmit() {
        let result = "";
        if (this.$refs.edittemporaryemployeesform.validate()) {
          this.edittemporaryemployees.ApiKey = this.ApiKey;
          result = await this.$http.post(
            "temporaryemployees.update.php",
            this.edittemporaryemployees
          );        
          if (result.data.status == true) {
            this.temporaryemployees = result.data;
            Swal.fire({
              icon: "success",
              title: "แก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.temporaryemployeesQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "แก้ไขข้อมูลผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.edittemporaryemployeesdialog = false;
        }
      },
      async temporaryemployeesDelete(temEmIDReGis) {
        let result = await this.$http.post("temporaryemployees.php", {
          ApiKey: this.ApiKey,
          temEmIDReGis: temEmIDReGis
        });
        this.edittemporaryemployees = result.data;
        this.deletetemporaryemployeesdialog = true;
      },
      async deleteDocfile() {
        this.edittemporaryemployees.ApiKey = this.ApiKey;
        this.edittemporaryemployees.temCollegeEmRegDetail = "";
        let result;
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" + this.edittemporaryemployees.temCollegeEmRegDetail
        });
  
        result = await this.$http.post(
          "temporaryemployees.update.php",
          this.edittemporaryemployees
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.temporaryemployeesQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      },
  
      async deletetemporaryemployeesSubmit() {
        if (this.$refs.deletetemporaryemployeesform.validate()) {
          let result;
          result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" + this.edittemporaryemployees.temCollegeEmRegDetail
          });
  
          this.edittemporaryemployees.ApiKey = this.ApiKey;
          result = await this.$http.post(
            "temporaryemployees.delete.php",
            this.edittemporaryemployees
          );
          if (result.data.status == true) {
            this.temporaryemployees = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.temporaryemployeesQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.deletetemporaryemployeesdialog = false;
        }
      }
    },
    computed: {
      time_stamp() {
        const d = new Date();
        let time = d.getTime();
        return time;
      },
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      color() {
        return "teal";
      }
    }
  };
  </script>
  <style scoped></style>
  