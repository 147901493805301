<template>
    <div>  
      <v-container>
        <base-material-card
          icon="mdi-clipboard-text"
          title="ประกาศ คัดเลือก/แข่งขัน บุคลากร"
          class="px-5 py-3"
        >
        <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />       
          <v-data-table
            color="success"
            :loading="loading"
            :headers="headers"
            :items="temporarycollegeemregs"
            :search="search"
          >
            <template v-slot:item.temCollegeEmRegPosition="{ item }">
              <span v-if="item.temCollegeEmRegPosition === 'governmentteach'"
                >พนักงานราชการ(สายการสอน)</span
              >
              <span v-else-if="item.temCollegeEmRegPosition === 'governmentsp'"
                >พนักงานราชการ(สายสนับสนุน)</span
              >
              <span v-else-if="item.temCollegeEmRegPosition === 'temporary'"
                >ครูอัตราจ้าง</span
              >
              <span v-else-if="item.temCollegeEmRegPosition === 'temporary_job'"
                >ลูกจ้างชั่วคราว</span
              >
            </template>
  
            <template v-slot:item.temCollegeEmRegHiring="{ item }">
              <span v-if="item.temCollegeEmRegHiring === 'month'"
                >รายเดือน</span
              >
              <span v-else-if="item.temCollegeEmRegHiring === 'day'"
                >รายวัน</span
              >
              <span v-else-if="item.temCollegeEmRegHiring === 'hour'"
                >รายชั่วโมง</span
              >
            </template>
  
            <template v-slot:item.temCollegeEmRegDateEnd="{ item }">
              {{
                item.temCollegeEmRegDateEnd
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
  
            <template v-slot:item.temCollegeEmRegDetail="{ item }">
              <v-btn
                icon
                :href="'/HRvecfiles/' + item.temCollegeEmRegDetail"
                target="_blank"
              >
                <v-icon color="info">mdi-file-document</v-icon>
              </v-btn>
            </template>
  
            <template v-slot:item.temCollegeEmStatus="{ item }">
              <v-icon v-if="item.temCollegeEmStatus === '1'" color="green"
                >mdi-lightbulb-on</v-icon
              >
              
              <v-icon v-else-if="item.temCollegeEmStatus === '0'" color="black"
                >mdi-lightbulb</v-icon
              >
          
            </template>
  
            <!-- <template v-slot:item.actions="{ item }">
              <v-icon
                color="yellow"
                @click.stop="temporarycollegeemregEdit(item.temCollegeEmRegID)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.action_s="{ item }">
              <v-icon
                color="red"
                @click.stop="temporarycollegeemregDelete(item.temCollegeEmRegID)"
              >
                mdi-delete
              </v-icon>
            </template> -->
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
              >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
            >
          </v-data-table>
        </base-material-card> 
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          top
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <v-card-text>
            {{ snackbar.text }}
          </v-card-text>
  
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.show = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        loading: true,
        ApiKey: "HRvec2021",
        valid: true,     
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
        temporarycollegeemregs: [],    
        search: "",
        pagination: {},
        headers: [
          { text: "วิทยาลัย", align: "left", value: "college_name" },
          { text: "ตำแหน่ง", align: "left", value: "temCollegeEmRegPosition" },
          { text: "รายละเอียดตำแหน่ง", align: "left", value: "temCollegeEmRegPositionDetail" },
          {
            text: "รูปแบบการจ้าง",
            align: "left",
            value: "temCollegeEmRegHiring"
          },
          {
            text: "เงินจ้าง/เดือน",
            align: "left",
            value: "temCollegeEmRegSalary"
          },
          { text: "ปิดประกาศ", align: "center", value: "temCollegeEmRegDateEnd" },
          { text: "ค่าธรรมเนียม", align: "center", value: "temCollegeEmPay" },
          { text: "รายละเอียด", align: "center", value: "temCollegeEmRegDetail" },
          { text: "สถานะ", align: "center", value: "temCollegeEmStatus" },
         /*  {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "action_s",
            icon: "mdi-delete-forever"
          } */
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
  
        college: {},
        provinces: [],
        prefectures: [],
  
        collgegs: [],
        temporarycollegeemregstatus: [],
        regions: [],
        region_ena: true,
        documentsLinkfiles: null,
        documentsLinkfilesUpdate: null,
        rules: [
          value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
          value => !!value || ""
        ],
        position_select: [
          { text: "พนักงานราชการ(สายการสอน)", value: "governmentteach" },
          { text: "พนักงานราชการ(สายสนับสนุน)", value: "governmentsp" },
          { text: "ครูอัตราจ้าง", value: "temporary" },
          { text: "ลูกจ้างชั่วคราว", value: "temporary_job" }
        ],
        hiring: [
          { text: "รายเดือน", value: "month" },
          { text: "รายวัน", value: "day" },
          { text: "รายชั่วโมง", value: "hour" }
        ],
        user: []
      };
    },
    async mounted() {    
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http.post("user.php", {
        ApiKey: this.ApiKey,
        user_ID: userSession.user_ID
      });
      this.user = result.data;
      this.temporarycollegeemregQueryAll();
    },
    methods: {
      async temporarycollegeemregQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("temporarycollegeemreg.php", {
            ApiKey: this.ApiKey,
            temCollegeEmRegCode:this.user.college_code
          })
          .finally(() => (this.loading = false));
        this.temporarycollegeemregs = result.data;
      },    
    },
    computed: {
      time_stamp() {
        const d = new Date();
        let time = d.getTime();
        return time;
      },
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      color() {
        return "teal";
      }
    }
  };
  </script>
  