<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลสถิตินักเรียนนักศึกษา แยกตามปีการศึกษา"
        class="px-5 py-3"
      >
        <!--         <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-card>
              <div id="chart">
                <apexchart
                  type="bar"
                  height="500"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-card>
          </v-col>
        </v-row> -->

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="rate_work_std02s"
          :search="search"
        >
          <template v-slot:top>
            <div>
              <div class="text-right">
                <v-btn
                  :href="'#/admin/print_rate_work_college_std/'"
                  target="_blank"
                  fab
                  small
                  color="info"
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
              </div>
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>
  </div>
</template>
<script>
import VueMoment from "vue-moment";
import moment from "moment-timezone";
export default {
  name: "rate_work_std02",
  data() {
    return {
      VueMoment,
      moment,
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,

      rate_work_std02s: [],
      search: "",
      pagination: {},
      headers: [
        {
          text: "รหัส",
          align: "center",
          value: "user_code"
        },
        { text: "วิทยาลัย", align: "left", value: "user_firstname" },
        {
          text: "ช.1/65",
          align: "center",
          value: "ch165"
        },
        {
          text: "ช.2/65",
          align: "center",
          value: "ch265"
        },

        {
          text: "ช.3/65",
          align: "center",
          value: "ch365"
        },

        {
          text: "ส.1/65",
          align: "center",
          value: "sh165"
        },
        {
          text: "ส.2/65",
          align: "center",
          value: "sh265"
        },


        {
          text: "ช.1/66",
          align: "center",
          value: "ch166"
        },
        {
          text: "ช.2/66",
          align: "center",
          value: "ch266"
        },

        {
          text: "ช.3/66",
          align: "center",
          value: "ch366"
        },

        {
          text: "ส.1/66",
          align: "center",
          value: "sh166"
        },
        {
          text: "ส.2/66",
          align: "center",
          value: "sh266"
        },



        {
          text: "ช.1/67",
          align: "center",
          value: "ch167"
        },
        {
          text: "ช.2/67",
          align: "center",
          value: "ch267"
        },

        {
          text: "ช.3/67",
          align: "center",
          value: "ch367"
        },

        {
          text: "ส.1/67",
          align: "center",
          value: "sh167"
        },
        {
          text: "ส.2/67",
          align: "center",
          value: "sh267"
        },



        {
          text: "ระยะสั้น 66",
          align: "center",
          value: "sc66"
        },

        {
          text: "ระยะสั้น 67",
          align: "center",
          value: "sc67"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ]
    };
  },
  created() {
    this.rate_work_std02QueryAll();
  },

  methods: {
    async rate_work_std02QueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("rate_work_std02.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.rate_work_std02s = result.data;   
    },

    calSum(a, b, c, d, e) {
      let aa = parseInt(a) || 0;
      let bb = parseInt(b) || 0;
      let cc = parseInt(c) || 0;
      let dd = parseInt(d) || 0;
      let ee = parseInt(e) || 0;
      return aa + bb + cc + dd + ee;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>

<!-- 
 .then(result => {
          result.data.forEach(element => {
            this.numX.push(element.user_firstname);
            this.numY.push(element.countPersonSubjectTeach);
            this.numZ.push(element.countPersonSubjectTeachGov);
            this.numAss.push(element.countPersonSubjectTeachTem);
          });
          this.series = [
            {
              data: this.numY
            },
            {
              data: this.numZ
            },
            {
              data: this.numAss
            }
          ];
          this.chartOptions = { xaxis: { categories: this.numX } };
          this.rate_work_std02s = result.data;
        })
        .finally(() => (this.loading = false));


-->
