<template>
  <div>
    <v-container>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลแจ้งปรับปรุงข้อมูลจากสถานศึกษา"
        class="px-5 py-3"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="personnel_temporary_notifys"
          :search="search"
        >
          <template v-slot:item.personnel_temporary_notifyStatus="{ item }">
            <v-chip
              color="red"
              dark
              v-if="item.personnel_temporary_notifyStatus === 'send'"
              >รอดำเนินการ</v-chip
            >
            <v-chip
              color="primary"
              dark
              v-else-if="item.personnel_temporary_notifyStatus === 'update'"
              >เสร็จสิ้น</v-chip
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
         
              @click="
                personnel_temporaryEdit(
                  item.id_card,
                  item.personnel_temporary_notifyID
                )
              "
              fab
              color="warning"
              rounded
              small
            >
              <v-icon>
                mdi-pencil
              </v-icon></v-btn
            >
          </template>

          <template v-slot:item.actionDel="{ item }">
            <v-btn
            v-if="item.personnel_temporary_notifyStatus === 'send'"
              @click="
                personnel_temporaryUPdate(item.personnel_temporary_notifyID)
              "
              fab
              color="red"
              rounded
              small
            >
              <v-icon>
                mdi-transit-transfer
              </v-icon></v-btn
            >
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model editpersonnel_temporarydialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editpersonnel_temporarydialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_temporaryform" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" md="12">
                    <span class="font-weight-bold">1. ประเภทบุคคล/สังกัด</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      rounded
                      filled
                      v-model="editpersonnel_temporary.user_status"
                      :items="userstatus"
                      item-text="user_status_name"
                      item-value="user_status_sub"
                      label="Type"
                      required
                      :rules="[v => !!v || '']"
                      prepend-icon="mdi-format-list-bulleted-type"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.college_code"
                      :items="colleges"
                      item-text="college_name"
                      item-value="college_code"
                      label="วิทยาลัย"
                      @change="man_powerQuery()"
                      required
                      :rules="[v => !!v || '']"
                      prepend-icon="mdi-school"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold"
                      >2. ข้อมูลพื้นฐาน</span
                    ></v-col
                  >

                  <v-col cols="12" md="3">
                    <v-text-field
                      @input="validateInput()"
                      prepend-icon="mdi-account-key"
                      rounded
                      outlined
                      label="รหัสบัตรประชาชน"
                      v-model="editpersonnel_temporary.id_card"
                      :rules="[
                        v => (!!v && v.length == 13) || '13  ID Card',
                        validateInputcheck === 'true' ||
                          'กำหนดรหัสบัตรประชาชนไม่ถูกต้อง'
                      ]"
                      maxlength="13"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      :items="prefixs"
                      item-text="prefix_name"
                      item-value="prefix_name"
                      prepend-icon="mdi-account-box"
                      rounded
                      outlined
                      label="คำนำหน้าชื่อ"
                      v-model="editpersonnel_temporary.title_s"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="mdi-account-box"
                      rounded
                      outlined
                      label="ชื่อ"
                      v-model="editpersonnel_temporary.frist_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      prepend-icon="mdi-account-box"
                      rounded
                      outlined
                      label="นามสกุล"
                      v-model="editpersonnel_temporary.last_name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      :items="days"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.brith_day"
                      label="วันที่เกิด : 8"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      :items="mounts"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.brith_month"
                      label="เดือนเกิด : 8"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      :items="years"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.brith_year"
                      label="ปีเกิด : 2531"
                      type="number"
                      maxlength="4"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-cellphone"
                      rounded
                      outlined
                      label="เบอร์โทร"
                      v-model="editpersonnel_temporary.tel_p"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-email"
                      rounded
                      outlined
                      label="E-mail"
                      v-model="editpersonnel_temporary.e_mail"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold">3. ตำแหน่ง</span></v-col
                  >

                  <v-col cols="12" md="3">
                    <v-autocomplete
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.position_name"
                      :items="userstatus"
                      item-text="user_status_name"
                      item-value="user_status_name"
                      label="ตำแหน่ง"
                    >
                 
                  </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.id_position"
                      :items="man_powers"
                      item-text="id_position"
                      item-value="id_position"
                      label="เลขที่ตำแหน่ง"
                    >
                   <template v-slot:item="{item}">
                    <div>
                      <div class="font-weight-bold">{{ item.id_position }}</div>
                      <div>{{ item.positions }} : {{ item.id_card }}</div>
                    </div>
                  </template>
                  </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      prepend-icon="mdi-account-settings"
                      outlined
                      rounded
                      :items="rang_names"
                      item-text="text"
                      item-value="value"
                      label="วิทยฐานะ"
                      v-model="editpersonnel_temporary.rang_name"
                      required
                      :rules="[v => !!v || '']"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.rang_level"
                      :items="rang_levels"
                      item-text="text"
                      item-value="value"
                      label="ระดับ"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold"
                      >4. วุฒิการศึกษา</span
                    ></v-col
                  >

                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      label="คุณวิฒิ"
                      v-model="editpersonnel_temporary.ed_abb"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      label="สาขาวิชา"
                      v-model="editpersonnel_temporary.ed_name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold">5. การบรรจุ</span></v-col
                  >

                  <v-col cols="12" md="4">
                    <v-autocomplete
                      prepend-icon="mdi-account-settings"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.type_personnel"
                      :items="type_personnelApp"
                      item-text="text"
                      item-value="value"
                      label="ประเภทการบรรจุ"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="4" class="pa-5">
                    <v-checkbox
                      v-model="editpersonnel_temporary.rang_condition"
                      value="1"
                      hide-details
                      class="shrink mr-2 mt-0"
                      label="กลุ่ม จชต. ชายแดนภาคใต้"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      prepend-icon="mdi-content-duplicate"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.order_app_now"
                      label="คำสั่งที่บรรจุ"
                    ></v-text-field>
                  </v-col>

                  <!-- บรรจะ -->
                  <v-col cols="12" md="4">
                    <v-select
                      :items="days"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar-clock"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.appoin_day"
                      label="วันที่บรรจุ"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      :items="mounts"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar-clock"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.appoin_month"
                      label="เดือนบรรจุ"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      :items="years"
                      item-text="text"
                      item-value="value"
                      prepend-icon="mdi-calendar-clock"
                      rounded
                      outlined
                      v-model="editpersonnel_temporary.appoin_year"
                      label="ปีบรรจุ"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid grey;" />
                  </v-col>
                  <v-col cols="12" md="12"
                    ><span class="font-weight-bold"
                      >6. การปฏิบัติงาน ณ ปัจจุบัน</span
                    ></v-col
                  >

                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menu5"
                      v-model="menu5"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          prepend-icon="mdi-calendar-clock"
                          rounded
                          outlined
                          v-model="editpersonnel_temporary.date_app_now"
                          label="วันเดือนปีที่เริ่มปฏิบัติงาน ณ สถานศึกษาปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_app_now"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu5 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu5.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menu6"
                      v-model="menu6"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          prepend-icon="mdi-calendar-clock"
                          rounded
                          outlined
                          v-model="editpersonnel_temporary.date_app_positonnow"
                          label="วันเดือนปีที่เริ่มปฏิบัติงาน ใน ตำแหน่งปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_app_positonnow"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu6 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu6.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editpersonnel_temporarydialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editpersonnel_temporarySubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        auto-height
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">
          Close
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import VueMoment from "vue-moment";
import moment from "moment-timezone";
export default {
  name: "personnel_temporary_notify",
  data() {
    return {
      VueMoment,
      moment,
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },

      personnel_temporary_notifys: [],
      search: "",
      pagination: {},
      headers: [
        {
          text: "วิทยาลัย",
          align: "left",
          value: "college_name"
        },
        { text: "รหัสบัตร", align: "center", value: "id_card" },
        { text: "ชื่อนามสกุล", align: "left", value: "frist_names" },
        {
          text: "รายละเอียด",
          align: "left",
          value: "personnel_temporary_notifyDetail"
        },
        {
          text: "สถานะ",
          align: "center",
          value: "personnel_temporary_notifyStatus"
        },
        {
          text: "วันที่ร้องขอ",
          align: "left",
          value: "personnel_temporary_notifyDateTIme"
        },
        {
          text: "แก้ไขคำร้อง",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบคำร้อง",
          align: "center",
          value: "actionDel",
          icon: "mdi-file-document-edit"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      editpersonnel_temporary: [],
      editpersonnel_temporarydialog: false,
      data_syslog: {},
      userstatus: [],
      colleges: [],
      man_powers: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      type_personnelApp: [
        { text: "ครูพัฒนาท้องถิ่น", value: "local_dev" },
        { text: "ครูโอนย้าย", value: "transfer_position" },
        { text: "คัดเลือก(ภายใน)", value: "select" },
        { text: "แข่งขัน(ภายนอก)", value: "recruit" }
      ],
      rang_names: [
        
        { text: "ต้น", value: "ต้น" },
        { text: "ปฏิบัติงาน", value: "ปฏิบัติงาน" },
        { text: "ปฏิบัติการ", value: "ปฏิบัติการ" },
        { text: "ชำนาญงาน", value: "ชำนาญงาน" },
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],
      position_select: [
        { text: "ผู้อำนวยการ", value: "director" },
        { text: "รองผู้อำนวยการ", value: "se_director" },
        { text: "ข้าราชการครู", value: "teacher" },
        { text: "ศึกษานิเทศก์", value: "supervision" },
        { text: "38", value: "38" }
      ],
      rang_levels: [
        
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" }
      ],
      validateInputcheck: [],
      days: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
        { text: "13", value: "13" },
        { text: "14", value: "14" },
        { text: "15", value: "15" },
        { text: "16", value: "16" },
        { text: "17", value: "17" },
        { text: "18", value: "18" },
        { text: "19", value: "19" },
        { text: "20", value: "20" },
        { text: "21", value: "21" },
        { text: "22", value: "22" },
        { text: "23", value: "23" },
        { text: "24", value: "24" },
        { text: "25", value: "25" },
        { text: "26", value: "26" },
        { text: "27", value: "27" },
        { text: "28", value: "28" },
        { text: "29", value: "29" },
        { text: "30", value: "30" },
        { text: "31", value: "31" }
      ],
      mounts: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" }
      ],
      years: [
        { text: "2500", value: "2500" },
        { text: "2501", value: "2501" },
        { text: "2502", value: "2502" },
        { text: "2503", value: "2503" },
        { text: "2504", value: "2504" },
        { text: "2505", value: "2505" },
        { text: "2506", value: "2506" },
        { text: "2507", value: "2507" },
        { text: "2508", value: "2508" },
        { text: "2509", value: "2509" },
        { text: "2510", value: "2510" },
        { text: "2511", value: "2511" },
        { text: "2512", value: "2512" },
        { text: "2513", value: "2513" },
        { text: "2514", value: "2514" },
        { text: "2515", value: "2515" },
        { text: "2516", value: "2516" },
        { text: "2517", value: "2517" },
        { text: "2518", value: "2518" },
        { text: "2519", value: "2519" },
        { text: "2520", value: "2520" },
        { text: "2521", value: "2521" },
        { text: "2522", value: "2522" },
        { text: "2523", value: "2523" },
        { text: "2524", value: "2524" },
        { text: "2525", value: "2525" },
        { text: "2526", value: "2526" },
        { text: "2527", value: "2527" },
        { text: "2528", value: "2528" },
        { text: "2529", value: "2529" },
        { text: "2530", value: "2530" },
        { text: "2531", value: "2531" },
        { text: "2532", value: "2532" },
        { text: "2533", value: "2533" },
        { text: "2534", value: "2534" },
        { text: "2535", value: "2535" },
        { text: "2536", value: "2536" },
        { text: "2537", value: "2537" },
        { text: "2538", value: "2538" },
        { text: "2539", value: "2539" },
        { text: "2540", value: "2540" },
        { text: "2541", value: "2541" },
        { text: "2542", value: "2542" },
        { text: "2543", value: "2543" },
        { text: "2544", value: "2544" },
        { text: "2545", value: "2545" },
        { text: "2546", value: "2546" },
        { text: "2547", value: "2547" },
        { text: "2548", value: "2548" },
        { text: "2549", value: "2549" },
        { text: "2550", value: "2550" },
        { text: "2551", value: "2551" },
        { text: "2552", value: "2552" },
        { text: "2553", value: "2553" },
        { text: "2554", value: "2554" },
        { text: "2555", value: "2555" },
        { text: "2556", value: "2556" },
        { text: "2557", value: "2557" },
        { text: "2558", value: "2558" },
        { text: "2559", value: "2559" },
        { text: "2560", value: "2560" }
      ],
      prefixs: [],
      updatepersonnel_temporary_notify:{}
    };
  },
  async mounted() {
    this.personnel_temporary_notifyQueryAll();
    let user_status_result;
    user_status_result = await this.$http.post("user_status.php", {
      ApiKey: this.ApiKey
    });
    this.userstatus = user_status_result.data;

    let result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });
    this.colleges = result.data;
    let prefix = await this.$http.post("prefix.php", { ApiKey: this.ApiKey });
    this.prefixs = prefix.data;
    },

  methods: {
    async man_powerQuery() {     
      let man_power_result;
      man_power_result = await this.$http.post("jor18.php", {
        ApiKey: this.ApiKey,
        j18college_code: this.editpersonnel_temporary.college_code      
      });
      this.man_powers = man_power_result.data;
               
    },

    async personnel_temporaryEdit(id_card, personnel_temporary_notifyID) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.editpersonnel_temporary.p_word = "";
      this.editpersonnel_temporary.personnel_temporary_notifyID = personnel_temporary_notifyID;
      this.man_powerQuery()
      this.validateInput();
      this.editpersonnel_temporarydialog = true;
    },

    async editpersonnel_temporarySubmit() {
      if (this.$refs.editpersonnel_temporaryform.validate()) {
        this.editpersonnel_temporary.ApiKey = this.ApiKey;
        if (this.editpersonnel_temporary.p_word == "")
          delete this.editpersonnel_temporary.p_word;

        if (this.editpersonnel_temporary.rang_condition == null)
          this.editpersonnel_temporary.rang_condition = "0";

        let result = await this.$http.post(
          "personnel_temporary.update.admin.php",
          this.editpersonnel_temporary
        );

        this.editpersonnel_temporary.personnel_temporary_notifyStatus =
          "update";
        let resultNotices = await this.$http.post(
          "personnel_temporary_notify.update.php",
          this.editpersonnel_temporary
        );
        
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporary_notifyQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editpersonnel_temporarydialog = false;
      }
    },

    async personnel_temporaryUPdate(personnel_temporary_notifyID) {
      Swal.fire({
        title: "เปลี่ยนสถานะการดำเนินการ",
        text: "เปลี่ยนเป็นสถานะ เสร็จสิ้น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.updatepersonnel_temporary_notify.ApiKey = this.ApiKey;
          this.updatepersonnel_temporary_notify.personnel_temporary_notifyStatus = "update";
          this.updatepersonnel_temporary_notify.personnel_temporary_notifyID = personnel_temporary_notifyID;
          let resultNotices = await this.$http.post(
            "personnel_temporary_notify.update.php",
            this.updatepersonnel_temporary_notify
          );   
          if (resultNotices.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_temporary_notifyQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    
    },

    async personnel_temporary_notifyQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary_notify.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.personnel_temporary_notifys = result.data;
    },

    async validateInput() {
      const maxLength = 13;
      const regex = /^[0-9]\d*$/;
      let value = this.editpersonnel_temporary.id_card;
      let i, sum;
      if (
        value !== undefined &&
        value.toString().length == maxLength &&
        value.match(regex)
      ) {
        if (value.length != 13) return false;
        for (i = 0, sum = 0; i < 12; i++) {
          sum += parseInt(value.charAt(i)) * (13 - i);
        }
        let mod = sum % 11;
        let check = (11 - mod) % 10;
        if (check == parseInt(value.charAt(12))) {
          this.validateInputcheck = "true";
        }
      } else {
        this.validateInputcheck = "false";
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
