var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"ประกาศ คัดเลือก/แข่งขัน บุคลากร"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"color":"success","loading":_vm.loading,"headers":_vm.headers,"items":_vm.temporarycollegeemregs,"search":_vm.search},scopedSlots:_vm._u([{key:"item.temCollegeEmRegPosition",fn:function(ref){
var item = ref.item;
return [(item.temCollegeEmRegPosition === 'governmentteach')?_c('span',[_vm._v("พนักงานราชการ(สายการสอน)")]):(item.temCollegeEmRegPosition === 'governmentsp')?_c('span',[_vm._v("พนักงานราชการ(สายสนับสนุน)")]):(item.temCollegeEmRegPosition === 'temporary')?_c('span',[_vm._v("ครูอัตราจ้าง")]):(item.temCollegeEmRegPosition === 'temporary_job')?_c('span',[_vm._v("ลูกจ้างชั่วคราว")]):_vm._e()]}},{key:"item.temCollegeEmRegHiring",fn:function(ref){
var item = ref.item;
return [(item.temCollegeEmRegHiring === 'month')?_c('span',[_vm._v("รายเดือน")]):(item.temCollegeEmRegHiring === 'day')?_c('span',[_vm._v("รายวัน")]):(item.temCollegeEmRegHiring === 'hour')?_c('span',[_vm._v("รายชั่วโมง")]):_vm._e()]}},{key:"item.temCollegeEmRegDateEnd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(item.temCollegeEmRegDateEnd,"add", "543 years"),"D MMMM YYYY"))+" ")]}},{key:"item.temCollegeEmRegDetail",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","href":'/HRvecfiles/' + item.temCollegeEmRegDetail,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-file-document")])],1)]}},{key:"item.temCollegeEmStatus",fn:function(ref){
var item = ref.item;
return [(item.temCollegeEmStatus === '1')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-lightbulb-on")]):(item.temCollegeEmStatus === '0')?_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-lightbulb")]):_vm._e()]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.snackbar.icon))]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }